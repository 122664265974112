import { WixLineItem } from '../model/cartModel';

export function calculateTotal(shippingCost: number, items: WixLineItem[]): number {
    if (items) {
        const subtotal: number = items
            .map((item) => {
                console.log('Item price: ', item.price.amount);
                return item.price.amount;
            })
            .reduce((sum, item) => sum + parseFloat(item), 0);
        return subtotal + (shippingCost || 0);
    }
    return 0;
}
